
/*------------------------------------*\
    #BLOG
\*------------------------------------*/

// Archive Page
body.blog {

    .hentry {
        margin-bottom: 3rem;


        p {
            margin-bottom: .5rem;
        }

        .read-more {
            margin-bottom: 0;

            a {
                color: $black;

                &:hover,
                &:focus {
                    color: $anchor-color-hover;
                }

            }

        }

    }

}

.byline {
    color: $c-ming;
    font-weight: 500;
}

