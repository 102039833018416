
/*------------------------------------*\
    #CTA-BAR
\*------------------------------------*/

.cta-bar {

    @extend .full-width;

    background-color: $c-sandstone;
    color: $white;
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding: rem-calc(36 0 10);
    text-align: center;

    p {
        font-size: rem-calc(28);
        font-weight: 400;
    }

}
