
/*------------------------------------*\
    #PAGE-HEADER
\*------------------------------------*/

.page-header {
    margin-bottom: rem-calc(45);

    &__hero {
        position: relative;
        background-color: $c-gray-700;
        // color: $white;
        padding-top: 20%;

        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;



        &:after {
            content: '';
            display: block;
            width: 100%;
            position: absolute;
            bottom: 0;
            background-image: url(../images/bg-page-hero-stripes.png);
            background-repeat: no-repeat;
            background-size: cover;
            padding-top: 4.1%;
        }

        // short, NO heading
        &--short {
            padding-top: 20%;
        }

        // short, with heading
        &--short1 {
            padding-top: 10%;
            padding-bottom: 6%;
        }

        // tall, NO heading
        &--tall {
            padding-top: 40%;
        }

        // tall, with heading
        &--tall1 {
            padding-top: 23%;
            padding-bottom: 13%;
        }

    }

    &__headings {
        position: relative;
        display: inline-block;


        h1, .h1 {
            margin: 0;
            color: $c-ming;
            font-size: rem-calc(26);
            line-height: 1.2em;

            display: inline-block;
            position: relative;
            z-index: 0;
            padding: rem-calc(12 20);

            @include breakpoint(medium) {
                font-size: rem-calc(32);
            }

            &:before {
                content: '';
                position: absolute;
                z-index: -1;
                top: 0;
                left: 0;
                width: 100vw;
                height: 100%;
                background-color: $c-linen;
            }

        }

        p {
            position: absolute;
            bottom: 90%;
            left: 20px;
            margin: 0;
            font-size: rem-calc(16);
            font-weight: 500;
            line-height: 1.2em;
            color: $c-linen;
            background-color: $c-ming;
            padding: 6px 15px 7px 15px;

            @include breakpoint(medium) {
                font-size: rem-calc(20);
            }

        }

    }

}
