
/*------------------------------------*\
    #LAYOUT
\*------------------------------------*/

// .grid-container.narrow {
//     @include breakpoint(medium) {
//         $medium-padding: map-get($grid-container-padding, medium) / 2;
//         padding-left: calc( #{$medium-padding} + 5%);
//         padding-right: calc( #{$medium-padding} + 5%);
//     }
// }

// .grid-container.narrow,
// .grid-container.narrow-large {
//     @include breakpoint(large) {
//         $large-padding: map-get($grid-container-padding, large) / 2;
//         padding-left: calc( #{$large-padding} + 5%);
//         padding-right: calc( #{$large-padding} + 5%);
//     }
// }

// .grid-container.narrow,
// .grid-container.narrow-large,
// .grid-container.narrow-xlarge {
//     @include breakpoint(xlarge) {
//         $xlarge-padding: map-get($grid-container-padding, xlarge) / 2;
//         padding-left: calc( #{$xlarge-padding} + 5%);
//         padding-right: calc( #{$xlarge-padding} + 5%);
//     }
// }





// Make container within a limited-width parent
// fill the browser width.
.full-width {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
}





// Add alignment classes
.aligncenter,
.alignleft,
.alignright {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: $paragraph-margin-bottom;

    @at-root p #{&}:last-child {
        // margin-bottom: 0;
    }

}

.alignnone {
    margin-bottom: $paragraph-margin-bottom;

    @at-root p #{&}:first-child:last-child {
        margin-bottom: 0;
    }

}
// p .alignnone:last-child {
//     margin-bottom: 0;
// }

@include breakpoint(medium) {
    .alignleft {
        float: left;
        margin: rem-calc(7) 1.6rem 1rem 0;
    }

    .alignright {
        float: right;
        margin: rem-calc(7) 0 1rem 1.6rem;
    }

}

// @include breakpoint(large) {
//     .alignleft {
//         margin: rem-calc(7) 3rem 2rem 0;
//     }

//     .alignright {
//         margin: rem-calc(7) 0 2rem 3rem;
//     }

// }

@include breakpoint(medium) {
    blockquote.alignleft,
    img.alignleft {
        display: inline-block;
    }

    blockquote.alignright,
    img.alignright {
        display: inline-block;
    }

}

.wp-caption {
    max-width: 100%;
    // background: $c-cararra;
    // padding: 5px;

    // Images inside wp-caption
    img {
        max-width: 100%;
        margin-bottom: 0;
        width: 100%;
    }

    p.wp-caption-text {
        font-size: rem-calc(14);
        // background-color: $c-mako;
        line-height: 1.3em;
        // margin: rem-calc(10 0);
        padding: rem-calc(10);
        text-align: center;
    }

}
