
/*------------------------------------*\
    #HOME
\*------------------------------------*/

.home-secondary {
    background-color: $c-sandstone;
    color: $white;
    margin-top: 2rem;
    margin-bottom: 3rem;
    padding: rem-calc(36 0 10);

    h1, .h1,
    h2, .h2,
    h3, .h3,
    h4, .h4,
    h5, .h5,
    h6, .h6 {
        color: $white;
    }

}
