
/*------------------------------------*\
    #HOME-SLIDER
\*------------------------------------*/

.home-slider {
    background-color: $c-gray-700;
    margin-bottom: rem-calc(45);

    &__slide {
        position: relative;
        color: $white;
        display: flex !important;
        align-items: flex-end;

        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;

        height: 66vw;
        min-height: rem-calc(320);

        @include breakpoint(large) {
            height: rem-calc(600);
        }

    }

    &__content {
        width: 100%;
        margin-bottom: 10%;

        @include breakpoint(large) {
            margin-bottom: rem-calc(100);
        }

    }

    h1, .h1 {
        text-align: right;
        margin: rem-calc(0 0 15);

        span {
            display: inline-block;
            position: relative;
            z-index: 0;
            padding: rem-calc(0 20 7);

             &:before {
                content: '';
                position: absolute;
                z-index: -1;
                top: 0;
                left: 0;
                width: 100vw;
                height: 100%;
                background-color: rgba($black,0.5);
            }

        }

    }

    &__button {
        text-align: right;
        padding-right: rem-calc(20);
    }

/*
    .slick-next,
    .slick-prev {
        padding: 1rem;
        color: $white;
        font-size: rem-calc(14);
        border: 2px solid $white;
        border-radius: 100%;
        width: rem-calc(50);
        height: rem-calc(50);
        cursor: pointer;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 10;
        text-indent: -9999px;
        transition: border-color $global-transition-speed ease-in, color $global-transition-speed ease-in;

        @include breakpoint(small only) {
            display: none !important;
        }

        &:hover,
        &:focus {
            background: none;
            border-color: $primary-color;
            color: $primary-color;
        }

        &:after {
            font-family: 'FontAwesome';
            text-indent: initial;
            position: absolute;
            left: 0;
            right: 0;
        }

    }

    .slick-prev {
        left: rem-calc(15);

        &:after {
            content: '\f053';
        }

    }

    .slick-next {
        right: rem-calc(15);

        &:after {
            content: '\f054';
        }

    }
*/

    .slick-dots {
        position: absolute;
        bottom: rem-calc(20);
        display: block;
        width: 100%;
        padding: 0;
        margin: 0;
        list-style: none;
        text-align: center;

        li {
            display: inline-block;
            margin: rem-calc(0 5);

            button {
                background-color: transparent;
                border: 1px solid $white;
                border-radius: 100%;
                font-size: 0;
                line-height: 0;
                text-indent: -9999px;
                width: rem-calc(10);
                height: rem-calc(10);
                cursor: pointer;
                transition: background-color $global-transition-speed ease-in, border-color $global-transition-speed ease-in;

                &:hover,
                &:focus {
                    background-color: $primary-color !important;
                    border-color: $primary-color !important;
                }

            }

            &.slick-active {
                button {
                    background-color: $white;
                }

            }

        }

    }

}
