
/*------------------------------------*\
    #BLOCK-LINKS
\*------------------------------------*/

.block-links {
    text-align: center;
    margin-top: 4rem;
    margin-bottom: 2rem;

    h3 {
        color: $c-mako;
        font-weight: 400;
        margin-bottom: rem-calc(15);
    }

    a {
        display: block;
        margin-bottom: $paragraph-margin-bottom;
    }

    &__image {
        position: relative;
        border-radius: 50%;
        overflow: hidden;

        img {
            width: 100%;
        }

    }

    &__content {
        background-color: rgba($black,.7);
        color: $white;
        display: flex;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        opacity: 0;
        transition: opacity $global-transition-speed;

        &-inner {
            align-self: center;
            width: 100%;
            padding: 0 12%;
        }

        p {
            line-height: 1.2em;
            margin-bottom: rem-calc(10);

            &:last-child(){
                margin-bottom: 0
            }

        }

        strong {
            font-size: rem-calc(16);
        }

    }

    a:hover,
    a:focus {
        .block-links__content {
            opacity: 1;
        }
    }

}
