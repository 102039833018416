
/*------------------------------------*\
    TYPE HELPERS
\*------------------------------------*/

.no-wrap {
    white-space: nowrap;
}

.no-mb {
    margin-bottom: 0 !important;
}

a.no-underline {
    text-decoration: none;
}

.text-primary-color {
    color: $primary-color;
}

.text-black {
    color: $black;
}

.text-hemlock,
.text-dark-green {
    color: $c-hemlock;
}





/*------------------------------------*\
    #TYPOGRAPHY
\*------------------------------------*/

a {
    transition: color $global-transition-speed ease-out, background-color $global-transition-speed ease-out;
}

a[href^="tel:"] {
    text-decoration: none;
}

h1, .h1,
h2, .h2,
h3, .h3 {
    font-family: $font-family-montserrat;
    font-weight: 400;
}




.full-width {

    // Dark Blue
    &.ming {
        background-color: $c-ming;
    }

    // Blue
    &.gothic {
        background-color: $c-gothic;
    }

    // Light Blue
    &.mystic {
        background-color: $c-mystic;
    }

    // Light Orange
    &.linen {
        background-color: $c-linen;
    }


    &.ming,
    &.gothic {
        color: $white;
        font-weight: 400;

        h1, .h1,
        h2, .h2,
        h3, .h3,
        h4, .h4,
        h5, .h5,
        h6, .h6 {
            color: $white;
        }

    }

}
