
/*------------------------------------*\
    #WIDGETS
\*------------------------------------*/

.widget {

    .widgettitle {
        // color: $c-sandstone;
        // font-weight: 400;
        font-family: $font-family-montserrat;
        margin-bottom: .7rem;
        font-size: rem-calc(24);
    }

    > ul {
        list-style: none;
        margin-left: 0;

        > li {
            line-height: 1.2em;
            margin-bottom: rem-calc(10);
        }

    }

    .post-date {
        font-size: .8em;

        &:before {
            content: '- ';
        }

    }

}

.widget_recent_entries {
    background-color: $c-mystic;
    padding: rem-calc(20 15);
    margin-bottom: 1rem;

    > ul {
        margin-bottom: 0;
        font-weight: 500;

        > li {
            margin-bottom: rem-calc(15);
        }

    }

}
