

/*------------------------------------*\
    #GRAVITY-FORMS
\*------------------------------------*/

//  GF messes some styles up, so this is to try and fix those things





// Gravity Forms medium breakpoint will probably not be the same as Foundation's,
// so set GF's medium breakpoint here.
$gform-medium-bp: 641;





.gform_wrapper {
    margin: rem-calc(0 0 30) !important;
}
@include breakpoint($gform-medium-bp) {
    .gform_wrapper ul.gform_fields {
        width: calc(100% + 16px);
    }

    .gform_wrapper .field_sublabel_below .ginput_complex.ginput_container {
        margin-bottom: -12px;
    }

}

.gform_wrapper h1,
.gform_wrapper h2,
.gform_wrapper h3,
.gform_wrapper span.gform_description {
    font-weight: inherit !important;
}

.gform_wrapper span.gform_description {
    width: auto !important;
}



.gform_wrapper .gsection .gfield_label,
.gform_wrapper h2.gsection_title,
.gform_wrapper h3.gform_title {
    font-weight: normal;
}



// Labels

.gform_wrapper .field_sublabel_above .ginput_complex.ginput_container label,
.gform_wrapper .field_sublabel_above div[class*=gfield_date_].ginput_container label,
.gform_wrapper .field_sublabel_above div[class*=gfield_time_].ginput_container label,
.gform_wrapper .field_sublabel_below .ginput_complex.ginput_container label,
.gform_wrapper .field_sublabel_below div[class*=gfield_date_].ginput_container label,
.gform_wrapper .field_sublabel_below div[class*=gfield_time_].ginput_container label {
    font-size: $form-label-font-size - rem-calc(2);
    font-weight: $global-weight-normal;
}
.gform_wrapper label.gfield_label {
    font-size: $form-label-font-size !important;
    font-weight: 400 !important;
    text-transform: uppercase;
}
.gform_wrapper .gfield_required {
    color: $alert-color;
}


body .gform_wrapper .top_label div.ginput_container {
    margin-top: 0 !important;
}

.gform_wrapper input,
.gform_wrapper select,
.gform_wrapper textarea {
    margin-bottom: 0;
}

.gform_wrapper .description_above .gfield_description {
    padding-bottom: 0;
}

.gform_wrapper input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]) {
    padding: $input-padding !important;
}

.gform_wrapper .gform_footer {
    padding: 0 !important;
}



// Time

.gform_wrapper .ginput_container_time input {
    display: inline-block;
}



// Submit
// @include breakpoint(small only) {

//     .gform_wrapper input[type=submit] {
//         line-height: 1 !important;
//     }
// }

.gform_wrapper input[type=submit] {

    // @extend .secondary;

    @include breakpoint(small only) {
        line-height: 1 !important;
    }
}




// Error

.gform_wrapper li.gfield.gfield_error,
.gform_wrapper li.gfield.gfield_error.gfield_contains_required.gfield_creditcard_warning
{
    border: 1px solid rgba($alert-color,.3);
    padding: 5px !important;
    background-color: rgba($alert-color,.05);
}

.gform_wrapper div.validation_error,
.gform_wrapper .gfield_error .gfield_label,
.gform_wrapper .validation_message
{
    color: $alert-color;
}

.gform_wrapper div.validation_error,
.gform_wrapper li.gfield_error input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]),
.gform_wrapper li.gfield_error select,
.gform_wrapper li.gfield_error textarea
{
    border-color: $alert-color;
}



// Success

.gform_confirmation_message {
    @extend .callout;
    @extend .callout.success;
}



// Show For SR

.gform_wrapper .screen-reader-text {
    @extend .show-for-sr;
}





/*------------------------------------*\
    #DARK-MODE
\*------------------------------------*/


.cta-bar .gform_wrapper {

    label,
    .gfield_required {
        color: $white;
    }

}
