
/*------------------------------------*\
    #FOOTER
\*------------------------------------*/

.site-footer {
    background-color: $c-avocado;
    color: $white;
    padding-top: rem-calc(25);
    padding-bottom: rem-calc(15);
    margin-top: rem-calc(45);

    a {
        color: $white;

        &:hover,
        &:focus {
            color: $c-mystic;
        }

    }

    p {
        margin-bottom: 1rem;
    }

    @include breakpoint(medium) {
        &__logo {
            transform: translateX(-20px);
        }
    }

    &__tagline {
        font-size: rem-calc(14);
        font-family: Helvetica, Arial, sans-serif;
        font-weight: bold;
        line-height: 1.2em;
        font-weight: bold;
    }

    &__contact {
        list-style: none;
        margin: rem-calc(0 0 20 0);
        font-size: rem-calc(17);
        font-family: Helvetica, Arial, sans-serif;
        font-weight: bold;
        line-height: 1.2em;

        li {
            margin-bottom: rem-calc(9);
        }

        a {
            display: inline-block;
            position: relative;
            padding-left: rem-calc(35);

            &:hover,
            &:focus {
                text-decoration: underline;
            }

            &:before {
                content: '';
                display: block;
                width: rem-calc(24);
                height: rem-calc(24);
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                position: absolute;
                top: rem-calc(-1);
                left: 0;
            }

            &.phone:before {
                background-image: url(../images/icon-phone-white.svg)
            }

            &.email:before {
                background-image: url(../images/icon-email-white.svg)
            }

            &.map-marker:before {
                background-image: url(../images/icon-map-marker-white.svg)
            }

        }

    }

    // #footer-links {
    //     line-height: .9em;
    //     list-style: none;
    //     margin-left: 0;
    //     margin-bottom: rem-calc(10);

    //     li {
    //         margin-bottom: rem-calc(5);
    //     }

    //     a {
    //         text-transform: uppercase;
    //         color: $white;
    //         font-size: rem-calc(15);
    //         font-weight: 700;

    //         &:hover,
    //         &:focus {
    //             color: $primary-color;
    //         }

    //     }

    // }


    #footer-links {
        float: right;
        margin: rem-calc(5 0 20);

        a {
            color: $white;
            font-size: rem-calc(17);
            font-weight: 500;
        }

        .active > a,
        .is-active > a {
            background: transparent;
        }

        // top level
        > li {
            position: relative;

            &:not(:first-child) {
                margin-left: rem-calc(20);
            }

            > a {
                padding-top: rem-calc(5);
                padding-bottom: rem-calc(5);
                padding-left: 0;
                padding-right: 0;
                border-bottom: 1px solid transparent;
                transition: color $global-transition-speed ease-out, border-color $global-transition-speed ease-out;
            }

            // &.current-menu-item:not(.living-options-li) > a,
            // &.current-page-parent > a,
            // &.current-menu-parent > a,
            &:hover > a,
            &:focus > a {
                color: $c-mystic;
                // text-decoration: underline;
                border-color: $c-mystic;
            }

            &.is-dropdown-submenu-parent > a:after {
                content: none;
            }

            &:not(:first-child):after {
                display: block;
                content: '';
                width: 2px;
                height: 14px;
                background-color: rgba($white,.5);
                position: absolute;
                top: 7px;
                left: -10px;
            }

        }


        // second+ levels
        .is-dropdown-submenu {
            background-color: rgba($white,0.7);
            // border-width: 4px 0 0;
            // border-color: $c-ochre;
            border: none;
            padding-top: rem-calc(10);
            padding-bottom: rem-calc(10);

            a {
                line-height: 1.2em;
                padding: rem-calc(8 20);
            }

            li:first-child a {
                border: none;
            }

            li.current-menu-item a,
            a:hover,
            a:focus {
                color: $c-ochre;
            }

        }

        // top level arrow
        > li.is-dropdown-submenu-parent > a:after {
            // border-top-color: $c-gray-400;
        }

        // second level arrow
        .is-dropdown-submenu .is-dropdown-submenu-parent.opens-right > a:after {
            // border-left-color: $c-gray-400;
        }

    }

    &__social {
        display: inline-block;
        font-size: rem-calc(35);
        line-height: 1em;
        margin-right: rem-calc(10px);

        a {
            margin: rem-calc(0 15 15 0);
        }

    }

}






//<div class="breakpoint-indicator"></div>

.breakpoint-indicator {
    background-color: #333;
    color: #fff;
    font-size: 14px;
    line-height: 1;
    padding: 4px;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 1000;

    &:after{
        @include breakpoint(small only) {
            content: 'S';
        }

        @include breakpoint(medium only) {
            content: 'M';
        }

        @include breakpoint(large only) {
            content: 'L';
        }

        @include breakpoint(xlarge) {
            content: 'XL';
        }

        // @include breakpoint(xxlarge) {
        //     content: 'XXL';
        // }

        // @include breakpoint(xxxlarge) {
        //     content: 'XXXL';
        // }

    }

}
