
/*------------------------------------*\
    #HEADER
\*------------------------------------*/

.preheader {
    background-color: $c-avocado;
    color: $white;
    font-family: $font-family-montserrat;
    font-weight: 600;

    a {
        color: $white;
    }

    &__message {
        font-size: rem-calc(15);
        padding: rem-calc(10 0);

        @include breakpoint(medium) {
            font-size: 1rem;
        }

    }

    &__menu {
        position: relative;
        z-index: 0;

        &:after {
            content: '';
            display: block;
            background-color: $c-ming;
            top: 0;
            left: 0;
            width: 100vw;
            bottom: 0;
            position: absolute;
            z-index: -1;
        }

    }

}

#secondary-nav {
    float: right;
    margin-top: 20px;
    margin-bottom: 20px;

    a {
        color: $white;
        font-size: rem-calc(14);
    }

    .active > a,
    .is-active > a {
        background: transparent;
    }

    // top level
    > li {
        position: relative;

        &:not(:first-child) {
            margin-left: rem-calc(20);
        }

        > a {
            padding: 0;
        }

        &.current-menu-item:not(.living-options-li) > a,
        &.current-page-parent > a,
        &.current-menu-parent > a,
        &:hover > a,
        &:focus > a {
            color: $c-ochre;
        }

        &.is-dropdown-submenu-parent > a:after {
            content: none;
        }

        &:not(:first-child):after {
            display: block;
            content: '';
            width: 2px;
            height: 14px;
            background-color: $white;
            position: absolute;
            top: 0;
            left: -11px;
        }

    }

}





.site-header {
    // background-color: $white;
    padding: rem-calc(10 0 15);

    @include breakpoint(medium) {
        padding: rem-calc(15 0 20);
    }

    &__logo {
        display: block;
        max-width: rem-calc(140);

        @include breakpoint(medium) {
            max-width: rem-calc(180);
        }

        @include breakpoint(large) {
            max-width: rem-calc(260);
        }

    }

    &__menu-button {
        margin-top: rem-calc(5);
        padding-left: 1em;
        padding-right: 1em;
        font-size: 1rem;

        .menu-icon {
            width: rem-calc(18);
            height: rem-calc(15);
            margin-left: rem-calc(2);
            margin-top: rem-calc(-2);

            &:after {
                height: 2px;
                background-color: $secondary-color;
                box-shadow: 0 5px 0 $secondary-color, 0 10px 0 $secondary-color;
                transition: background-color 0.25s ease-out, box-shadow 0.25s ease-out;
            }

        }

        &:hover,
        &:focus {
            .menu-icon:after {
                background-color: scale-color($secondary-color, $lightness: -50%);
                box-shadow: 0 5px 0 scale-color($secondary-color, $lightness: -50%), 0 10px 0 scale-color($secondary-color, $lightness: -50%);
            }
        }

    }

}


.header-contact {
    font-size: rem-calc(14);
    font-weight: bold;

    &__item {
        display: inline-block;

        &:not(:first-child) {
            margin-left: rem-calc(30);
        }
    }

    img {
        margin-right: rem-calc(7);
    }

}



#main-nav {
    float: right;
    margin-top: rem-calc(5);

    a {
        color: $c-ming;
        font-size: rem-calc(17);
        font-weight: 500;
    }

    .active > a,
    .is-active > a {
        background: transparent;
    }

    // top level
    > li {
        position: relative;

        &:not(:first-child) {
            margin-left: rem-calc(20);
        }

        > a {
            padding-top: rem-calc(15);
            padding-bottom: rem-calc(15);
            padding-left: 0;
            padding-right: 0;
        }

        &.current-menu-item:not(.living-options-li) > a,
        &.current-page-parent > a,
        &.current-menu-parent > a,
        &:hover > a,
        &:focus > a {
            color: $c-ochre;
        }

        &.is-dropdown-submenu-parent > a:after {
            content: none;
        }

        &:not(:first-child):after {
            display: block;
            content: '';
            width: 2px;
            height: 14px;
            background-color: $c-avocado;
            position: absolute;
            top: 16px;
            left: -10px;
        }

    }


    // second+ levels
    .is-dropdown-submenu {
        background-color: rgba($white,0.7);
        // border-width: 4px 0 0;
        // border-color: $c-ochre;
        border: none;
        padding-top: rem-calc(10);
        padding-bottom: rem-calc(10);

        a {
            line-height: 1.2em;
            padding: rem-calc(8 20);
        }

        li:first-child a {
            border: none;
        }

        li.current-menu-item a,
        a:hover,
        a:focus {
            color: $c-ochre;
        }

    }

    // top level arrow
    > li.is-dropdown-submenu-parent > a:after {
        // border-top-color: $c-gray-400;
    }

    // second level arrow
    .is-dropdown-submenu .is-dropdown-submenu-parent.opens-right > a:after {
        // border-left-color: $c-gray-400;
    }

}





/*------------------------------------*\
    #OFF-CANVAS
\*------------------------------------*/

.site-off-canvas {

    &__close {
        height: rem-calc(72);
    }

    .close-button {
        border-radius: 100%;
        border: 2px solid $c-gray-500;
        color: $c-gray-500;
        width: rem-calc(40);
        height: rem-calc(40);
        font-size: rem-calc(20);
        font-family: Arial, sans-serif;
        line-height: rem-calc(38);
        top: 1rem;
    }

    &__top {
        padding: rem-calc(0 20);

        .button.small.hollow {
            width: 100%;
        }

    }

    // Contact Info
    .header-contact {
        background-color: $c-gray-100;
        padding: rem-calc(20 20 5);
        margin-bottom: rem-calc(30);

        a {
            color: $body-font-color;

            &:hover,
            &:focus {
                color: $c-ochre;
            }

        }

        &__item {
            display: block;
            margin-left: 0;
            margin-bottom: rem-calc(15);
        }

        img {
            float: left;
        }

    }

}

#offcanvas-nav {
    padding: rem-calc(0 20);

    li {
        border-bottom: 2px solid $c-gray-100;

        &:last-child {
            border: none;
        }

    }

    a {
        color: $c-ming;
        // font-size: rem-calc(17);
        font-weight: 400;
        line-height: 1.2em;
        padding: rem-calc(18 0);
    }

    .active > a,
    .is-active > a {
        background: transparent;
    }

    // top level
    > li {

        > a {

        }

        &.active > a,
        &.is-active > a,
        &.current-menu-item > a,
        &.current_page_parent > a,
        &:hover > a,
        &:focus > a {
            color: $c-ochre;
        }

    }

    .submenu-toggle {
        border-radius: 100%;
        border: 2px solid $c-gray-500;
        width: rem-calc(26);
        height: rem-calc(26);
        top: rem-calc(12);
        transition: border-color $global-transition-speed ease-out;

        &:after {
            border-top-color: $c-gray-500;
            transition: border-color $global-transition-speed ease-out;
        }

        &:hover,
        &:focus {
            border-color: $c-gray-600;

            &:after {
                border-top-color: $c-gray-600;
            }

        }

    }

    .is-accordion-submenu {
        margin: rem-calc(0 0 15 15);

        li:first-child {
            border-top: 1px solid $c-gray-200;
        }

        li.current-menu-item a,
        a:hover,
        a:focus {
            color: $c-ochre;
        }

    }

}





#offcanvas-secondary-nav {
    background-color: $c-ming;
    padding: rem-calc(0 20);
    margin-top: rem-calc(10);

    li {
        border-bottom: 2px solid rgba($white,.1);

        &:last-child {
            border: none;
        }

    }

    a {
        color: $white;
        font-family: $font-family-montserrat;
        font-size: rem-calc(14);
        font-weight: 600;
        line-height: 1.2em;
        padding: rem-calc(15 0);
    }

    .active > a,
    .is-active > a {
        background: transparent;
    }

    // top level
    > li {

        > a {

        }

        &.active > a,
        &.is-active > a,
        &.current-menu-item > a,
        &.current_page_parent > a,
        &:hover > a,
        &:focus > a {
            color: $c-ochre;
        }

    }


}
